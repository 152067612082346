<template>
  <div v-if="Object.keys(userAccountData).length && ! userAccountData.is_active" class="message-app">
    <div class="message-app__body">
      <div class="message-app__status">ВНИМАНИЕ</div>
      <div class="message-app__content">
        <p>
          Вы не активировали приложение.
          Пока приложение не активировано, вы не можете взаимодействовать с ним из интернет-магазина.
          Это значит, что пользователи не смогут подписаться на товар, которого нет в наличии.
          Также приложение не будет следить за товарами и не будет уведомлять пользователей о товарах, появившихся в наличии.
        </p>
        <p>Пожалуйста, активируйте аккаунт.</p>
      </div>

      <app-button value="Активировать" v-on:click="init" />

      <p>{{ result }}</p>
    </div>

  </div>
</template>

<script>
import AppButton from './base-ui/app-button';

export default {
  name: 'app-init-account',
  data: function () {
    return {
      result: ''
    }
  },
  methods: {
    init: function () {
      this.$store.dispatch('initAccount')
        .then(newAccountData => {
          if (newAccountData && newAccountData.statusCode && newAccountData.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          this.result = 'Аккаунт успешно активирован';

          setTimeout(() => {
            this.$store.commit('SET_ACCOUNT_DATA', newAccountData);
          }, 2500)
        })
        .catch(() => {
          this.result = 'Ошибка при активации аккаунта. Повторите попытку';
        })
    },
    getUserAccountData: async function () {
      return this.$store.dispatch('getAccountData')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }
        })
    },

    getData: function() {
      if (! Object.keys(this.$store.state.account.accountData).length) {
        this.getUserAccountData();
      }
    }
  },
  computed: {
    userAccountData: function () {
      return this.$store.state.account.accountData;
    }
  },
  components: {
    'app-button': AppButton
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.message-app {
  border-radius: 30px;
  background-color: #fff;
  padding: 20px;
  //max-width: 600px;
  margin-bottom: 20px;
  border: 1px solid var(--color-red);

  &__status {
    font-weight: 700;
    color: var(--color-red);
  }

  &__content {
    font-size: 15px;
    color: var(--color-dark);
  }
}
</style>
