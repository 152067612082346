<template>
  <div class="loader" :class="isActive ? 'active': ''">
    <img :src="src">
  </div>
</template>

<script>
export default {
  name: 'loader',
  data: function () {
    return {
      src: require('../../assets/loading.gif'),
      isActive: false,
    }
  },
  methods: {
    open: function () {
      this.isActive = true;
    },
    close: function () {
      this.isActive = false;
    }
  }
}
</script>

<style lang="scss">
.loader {
  position: absolute;
  left: 0;
  top: 0;

  background-color: rgba(255, 255, 255, .75);

  width: 100%;
  height: 100%;

  z-index: 101;

  display: none;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }
}
</style>
