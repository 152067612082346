<template>
  <div class="">

    <div v-if="list.length && viewType === 2" class="subscribers-list subscribers-list_box">
      <div class="subscribers-list__item-box subscription-box" v-for="item in list" :key="item.id">
        <div class="subscription-box">
          <div class="subscription-box__image-wrap">
            <img :src="item.product_image" alt="" class="subscription-box__image">
          </div>
          <div class="subscription-box__content">
            <a :href="item.url" class="subscription-box__title"><span>{{`${item.product_name} ( ${item.product_mode_name} )`}}</span></a>
            <div class="subscription-box__id">{{`${item.product_id} / ${item.product_mode_id}`}}</div>
            <div class="subscription-box__status" :class="`subscription-box__status_color-${item.status}`">{{ item.status === 'new' ? 'Ожидает товара' : item.status === 'success' ? 'Уведомлен' : item.status === 'pending' ? 'Ожидает отправки' : 'Ошибка' }}</div>
            <div class="subscription-box__status">{{ item.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="list.length && viewType === 1" class="subscribers-list">
      <div class="app-container subscribers-list__container">
        <div class="app-container__header subscribers-list__header">
          <div class="subscribers-list__header-item subscribers-list__status">Статус</div>
          <div class="subscribers-list__header-item subscribers-list__email">Email</div>
          <div class="subscribers-list__header-item subscribers-list__id">ID товара > ID варианта</div>
          <div class="subscribers-list__header-item subscribers-list__title">Название</div>
          <div class="subscribers-list__header-item subscribers-list__time">Время подписки</div>
          <div class="subscribers-list__header-item subscribers-list__phone">Телефон</div>
          <div class="subscribers-list__header-item subscribers-list__time">Время выполнения</div>
        </div>
        <div class="subscribers-list__content">
          <div class="subscribers-list__item" v-for="item in list" :key="item.id">
            <app-subscribers-list-item :type="'status'" :value="item.status" />
            <app-subscribers-list-item :type="'email'" :value="item.email" />
            <app-subscribers-list-item :type="'id'" :value="`${item.product_id} -> ${item.product_mode_id}`" />
            <app-subscribers-list-item :link="item.url" :type="'title'" :value="`${item.product_name} ${item.product_mode_name}`" />
            <app-subscribers-list-item :type="'time'" :value="item.create_at" />
            <!--<app-subscribers-list-item :type="'time'" :value="item.update_at" /> -->
            <app-subscribers-list-item :type="'phone'" :value="item.phone" />
            <app-subscribers-list-item :type="'time-result'" :value="{ create: item.create_at, update: item.update_at }" />

<!--            <div @click="perform(item)" title="Выполнить принудительно" class="subscribers-list__confirm-btn">-->
<!--              <span>Выполнить принудительно</span>-->
<!--            </div>-->
          </div>
        </div>
      </div>

    </div>
    <div v-else class="subscribers-list__empty">
      <div class="app-container subscribers-list__empty-container">
        <div class="subscribers-list__empty-content">
          Ничего не найдено
        </div>
      </div>
    </div>
    <div class="toggle">
      <span @click="toggleViewType(1)">Список</span>
      <span @click="toggleViewType(2)">Карточка</span>
    </div>
  </div>

</template>

<script>
import appSubscribersListItem from './app-subscribers-list-item';

export default {
  name: 'app-subscribers-list',
  props: {
    list: {
      type: Array,
    }
  },
  data: function  () {
    return {
      viewType: 1,
    }
  },
  methods: {
    toggleViewType(type) {
      this.viewType = type
    },
    perform: function(item) {
      this.$store.dispatch('performSubscription', { body: {
          array: [item.id],
          email: item.email
        }
      })
      .then(response => {
        if (response && response.statusCode && response.statusCode === 401) {
          return this.$store.dispatch('logoutAccount')
        }

        return response;
      })
      .catch(error => {
        console.log('error', error);

        // todo
        throw error;
      })
    }
  },
  components: {
    'app-subscribers-list-item': appSubscribersListItem,
  }
}
</script>


<style lang="scss">


a.subscribers-list {
  &__title {
    color: var(--color-black);
  }
}

.subscribers-list {
  &_box {
    display: flex;
    flex-wrap: wrap;

    padding: 30px;
    background: #fff;
    border-radius: 30px;
    padding-bottom: 15px !important;
  }

  &__empty-content {
    padding: 20px 40px;
    color: #cacaca;
    min-height: 200px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0;
  }
  &__content {
    padding: 15px 0;
  }
  &__confirm-btn {
    position: absolute;
    left: 0;
    top: 0;

    background: #7437FD;
    height: 100%;

    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 100%;
    max-width: 219px;
    min-width: 219px;

    color: #fff;

    pointer-events: none;
    opacity: 0;
    transition: .5s;

    & span {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 36px;

    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;

    color: #747988;

    position: relative;

    &:hover {
      & .subscribers-list__confirm-btn {
        pointer-events: auto;
        opacity: 1;
        transition: .5s;
      }
    }
  }


  &__header-item {
    padding: 25px 20px 26px 26px !important;
  }






  &__status {
    //max-width: 219px;
    //min-width: 219px;
    //width: 100%;

    width: 14.28%;
  }
  &__email {
    //max-width: 200px;
    //min-width: 200px;
    //width: 100%;
    width: 14.28%;
  }
  &__id {
    //max-width: 187px;
    //min-width: 187px;
    //width: 100%;
    width: 14.28%;
  }
  &__title {
    //max-width: 300px;
    //min-width: 300px;
    //width: 100%;
    width: 14.28%;
  }
  &__time {
    //max-width: 170px;
    //min-width: 170px;
    //width: 100%;
    width: 14.28%;
  }
  &__phone {
    //max-width: 170px;
    //min-width: 170px;
    //width: 100%;
    width: 14.28%;
  }


}




.subscription-box {
  width: 240px;
  border-radius: 10px;
  overflow: hidden;

  margin-right: 15px;
  margin-bottom: 15px;

  &__content {
    border-width: 0 2px 2px 2px;
    border-color: var(--color-border);
    border-style: solid;
    border-radius: 0px 0px 10px 10px;
    background: #fff;

    padding: 10px;

    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #747988;
  }

  &__title {
    color: var(--color-black);
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    display: block;
  }

  &__image-wrap {
    width: 100%;
    aspect-ratio: 1.2;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__id {
    margin: 10px 0;
  }

  &__status {
    margin-bottom: 5px;

    &_color-new {
      color: var(--color-yellow);
    }
    &_color-success {
      color: var(--color-green);
    }
    &_color-error {
      color: var(--color-red);
    }
    &_color-pending {
      color: var(--color-violet);
    }
  }
}

.toggle {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: flex-start;

  & span {
    cursor: pointer;
    background-color: #7437FD;
    border-radius: 20px;
    padding: 7px 16px;
    margin-left: 10px;
    color: #fff;
  }
}


</style>
