<!--Сохраняет состояние фильтра-->
<!--возвращает значение наверх-->

<template>
  <div class="filter">
    <div class="filter__button">
      <app-button @click="openFilterModal" :type="'submit'" :value="'Фильтровать'" :width="'300px'" :height="'100%'" />
    </div>
    <div v-if="! isOpenModal" class="filter__actives">
      <div v-for="(value, name, index) in formData" :key="index" class="filter__active-item">
        <div v-if="value" class="filter__active-item-content">
          {{ resolutionName(name) }}
        </div>
      </div>
      <div v-if="isEmptyFilters" class="filter__active-empty">Нет активных фильтров</div>
    </div>
    <div @click="modalClick" class="filter__modal" :class="isOpenModal ? 'active': ''">
      <div class="filter__modal-content">

        <div @click="closeFilterModal" class="filter__modal-close-btn">
          <img :src="closeIconImg" alt="">
        </div>

        <form @submit.prevent="handleForm" class="filter__modal-form" :class="`filter__${className}`">
          <div class="app-form__field-item">
            <div class="app-form__field-name">Email</div>
            <app-input
                v-model="formData.email"
                :placeholder="'Email'"
                :name="'Email'"
                :type="'Email'"
                :class-name="'app-form__field'"
            />
          </div>
          <div class="app-form__field-item">
            <div class="app-form__field-name">Статус</div>
            <app-select
                :valueProp="getVueStatus(formData.status)"
                @change="changeStatusField"
                :list="['Ожидает товар', 'Уведомлён о наличии', 'Ошибка']"
                :defaultValue="'Статус подписки'"
            />
          </div>
          <div class="app-form__field-item">
            <div class="app-form__field-name">Id товара</div>
            <app-input
                v-model="formData.product_id"
                :placeholder="'Id товара'"
                :name="'productId'"
                :type="'number'"
                :class-name="'app-form__field'"
            />
          </div>
          <div class="app-form__field-item">
            <div class="app-form__field-name">Название товара</div>
            <app-input
                v-model="formData.product_name"
                :placeholder="'Название товара'"
                :name="'product_name'"
                :type="'text'"
                :class-name="'app-form__field'"
            />
          </div>
          <div class="app-form__field-item">
            <div class="app-form__field-flex-2">
              <div class="app-form__field-item">
                <div class="app-form__field-name">Время подписки: От</div>
                <app-input
                    v-model="formData.create_at_start"
                    :placeholder="'от'"
                    :name="'createAtStart'"
                    :type="'date'"
                    :class-name="'app-form__field'"
                />
              </div>
              <div class="app-form__field-item">
                <div class="app-form__field-name">Время подписки: До</div>
                <app-input
                    v-model="formData.create_at_end"
                    :placeholder="'до'"
                    :name="'createAtEnd'"
                    :type="'date'"
                    :class-name="'app-form__field'"
                />
              </div>
            </div>
          </div>

          <app-button :type="'submit'" value="Фильтровать" />
          <app-button @click="handleResetButton" :className="'mode-gray'" value="Сбросить" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import appButton from '../../components/base-ui/app-button'
import appInput from "../base-ui/app-input";
import appSelect from '../base-ui/app-select';

export default {
  name: 'app-subscribers-filter',
  data: function () {
    return {
      closeIconImg: require('../../assets/icons/close.svg'),
      isOpenModal: false,
      formData: {
        email: '',
        product_name: '',
        product_id: '',
        status: '',
        create_at_start: '',
        create_at_end: '',
      },
      isActiveResetButton: false,

      closeIcon: require('../../assets/icons/close.svg')
    }
  },
  props: {
    subscribersList: {
      type: Array,
    },
    className: {
      type: String,
    }
  },
  methods: {
    resolutionName(name) {
      if (name == 'email') { return 'Email' }
      if (name == 'product_name') { return 'Название продукта' }
      if (name == 'product_id') { return 'Id продукта' }
      if (name == 'status') { return 'Статус' }
      if (name == 'create_at_start') { return 'Время создания (от)' }
      if (name == 'create_at_end') { return 'Время создания (до)' }

      return 'Неопределенный фильтр'
    },
    modalClick: function (e) {
      if (e.target.classList.contains('filter__modal')) {
        this.closeFilterModal();
      }
    },
    getVueStatus(name) {
      if (name === 'new') {
        this.formData.status = 'new';
        return 'Ожидает товар'
      }
      if (name === 'success') {
        return 'Уведомлён о наличии';
      }
      if (name === 'error') {
        return 'Ошибка';
      }

      return '';
    },
    changeStatusField: function (value) {
      if (value === 'Ожидает товар') {
        this.formData.status = 'new';
        return;
      }
      if (value === 'Уведомлён о наличии') {
        this.formData.status = 'success';
        return;
      }
      if (value === 'Ошибка') {
        this.formData.status = 'error';
        return;
      }

      this.formData.status = '';
    },
    openFilterModal: function () {
      this.isOpenModal = true;
    },
    closeFilterModal: function () {
      this.isOpenModal = false;
    },

    emitValue: function () {
      console.log('formData', this.formData)
      this.$emit('filtered', this.formData);
    },
    handleResetButton: function () {
      this.formData.status = '';
      this.formData.create_at_end = '';
      this.formData.create_at_start = '';
      this.formData.email = '';
      this.formData.product_id = '';
      this.formData.product_name = '';

      this.emitValue()
      this.closeFilterModal();
    },
    handleForm: function () {
      this.emitValue();
      this.closeFilterModal();
    },
  },
  computed: {
    isEmptyFilters: function () {
      let res = '';

      for (let key in this.formData) {
        res += this.formData[key];
      }

      if (res) {
        return false;
      } else {
        return true;
      }
    }
  },
  components: {
    'app-input': appInput,
    'app-button': appButton,
    'app-select': appSelect
  }
}
</script>

<style lang="scss">
.filter {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: transparent !important;

  &__button {
    height: 55px;
  }

  &__modal {
    position: absolute;
    left: 9999999px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 99;

    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      left: 0;
    }
  }

  &__modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    & img {
      display: flex;
      height: auto;
      width: 30px;
    }
  }

  &__modal-content {
    max-width: 600px;
    width: 100%;
    background: #fff;
    border-radius: 30px;
    padding: 30px;
    position: relative;
    padding-top: 60px;
  }

  &__modal-form {
    display: flex;
    flex-direction: column;

    & button {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__reset {
    display: none;

    width: 24px;
    height: 24px;

    margin-right: 10px;

    cursor: pointer;

    position: absolute;
    right: 20px;
    top: calc(50% - 12px);

    &.active {
      display: block;
    }
  }

  &__field-wrap {
    position: relative;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    height: 100%;

    margin-right: 10px;
    flex-grow: 1;

  }

  &__field {
    flex-grow: 1;
    width: 100%;
    height: 100%;


    border: none;
    padding: 0 40px;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    outline: none;

    &::placeholder {
      letter-spacing: 0.01em;

      color: #A6ACBE;
    }
  }

  &__actives {
    margin-top: 10px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
  }

  &__active-item-content {
    margin-right: 8px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 13px;
    background: var(--color-black);
    color: #fff;
  }

  &__active-empty {
    opacity: .3;
  }

}

</style>
