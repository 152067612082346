<template>
  <div class="subscribers__counter">
    <div class="subscribers__counter-item" v-for="(value, name, index) in count" :key="index">
      <div class="subscribers__counter-title">
        {{ getTitle(name) }}
      </div>
      <div class="subscribers__counter-counter">{{value}};</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'app-subscribers-statistic',
  props: {
    count: {
      type: Object,
      require: true,
    }
  },
  methods: {
    getTitle: function (name) {
     if (name === 'statusConfirm') {
       return 'Всего подписок:'
     } else if (name === 'countConfirmResult') {
        return 'Всего выполнено:'
     } else if (name === 'countNotConfirmResult') {
       return 'Ожидают поступления / ошибка:'
     } else {
       return 'Всего подписок:'
     }
    }
  }
}
</script>
