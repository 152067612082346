<template>
  <div class="home">
    <app-init-account />
    <visits />
    <app-subscribers v-if="subscriptionsList" :count="subscriptionStat" :length="subscriptionsCounter" :data="subscriptionsList" :class-name="'index-page'" />
  </div>
</template>

<script>
import AppInitAccount from '../components/app-init-account';
import appSubscribers from '../components/subscribers/app-subscribers';
import visits from '../components/visits'

export default {
  name: 'home',
  components: {
    'app-init-account': AppInitAccount,
    'app-subscribers': appSubscribers,
    visits
  },
  computed: {
    subscriptionsList: function () {
      return this.$store.state.subscriptions.subscriptionsList || [];
    },
    subscriptionsCounter: function () {
      return this.$store.state.subscriptions.subscriptionsCounter || 0;
    },
    subscriptionStat: function () {
      return this.$store.state.subscriptions.subscriptionStat || {};
    }
  },
  methods: {
    getSubscriptions: function() {
      this.$store.dispatch('getSubscriptions', {
        page: 1,
        pageSize: 15,
      })
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }
        })
    },
    getSubscriptionStat: function () {
      this.$store.dispatch('getSubscriptionStat')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }
        })
    },

    getData: function () {
      if (! this.$store.state.subscriptions.subscriptionsList.length) {
        this.getSubscriptions()
      }
      if (! Object.keys(this.$store.state.subscriptions.subscriptionsList).length) {
        this.getSubscriptionStat()
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.home {
  //position: relative;

  padding: 30px;

  overflow: hidden;

  & .analytics {
    margin-bottom: 40px;
  }
}
</style>
