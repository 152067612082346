<template>
  <section class="subscribers" :class="`subscribers__${className}`">

    <app-loader ref="loader" />

    <div class="subscribers__title section-title">Подписки</div>

    <div class="subscribers__wrap">
      <div class="subscribers__header">
        <app-subscribers-statistic :count="count" />
        <app-subscribers-filter @filtered="filteredSubscribersList" :class-name="'index-page'" :subscribers-list="data" />
      </div>
      <app-subscribers-list ref="subscribers-list" :list="data" />


      <paginate
          v-if="pages >= 2"
          :page-count="pages"
          :click-handler="myCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'">
      </paginate>
    </div>

  </section>
</template>

<script>
import appSubscribersList from './app-subscribers-list';
import appSubscribersFilter from './app-subscribers-filter';
import appLoader from '../base-ui/app-loader'
import appSubscribersStatistic from './app-subscribers-statistic';

export default {
  name: 'app-subscribers',
  props: {
    length: {
      type: Number,
    },
    className: {
      type: String,
    },
    count: {
      type: Object,
      require: true,
    },
    data: {
      type: Array,
      require: true,
    }
  },
  methods: {
    myCallback: function (e) {
      this.getSubscriptionsList({ page: e, pageSize: 15 })
    },
    filteredSubscribersList: function (data) {
      if (data.product_id) {
        data.product_id = Number(data.product_id)
      }
      this.$store.commit('SET_SUBSCRIPTION_FILTER', data)
      this.getSubscriptionsList({ page: 1, pageSize: 15 })
    },
    getSubscriptionsList(opts) {
      this.$refs['loader'].open();

      this.$store.dispatch('getSubscriptions', opts)
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          this.$refs['loader'].close();
        })
        .catch(() => {
          this.$refs['loader'].close();
        })
    }
  },
  computed: {
    pages: function () {
      return Math.ceil(this.length / 15);
    }

  },
  components: {
    'app-subscribers-list': appSubscribersList,
    'app-subscribers-filter': appSubscribersFilter,
    'app-subscribers-statistic': appSubscribersStatistic,
    'app-loader': appLoader,
  },
}
</script>


<style lang="scss">

.subscribers {
  &__counter {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;

    margin-top: 11px;

    color: #747988;

    display: flex;
  }


  &__counter-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  &__counter-title {
    margin-right: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

</style>
