<template>
  <div class="visit">
    <div class="visit__chart">
      <apexchart :key="key" width="500px" type="area" :options="options" :series="series"></apexchart>
    </div>
    <div class="visit__content">
      <div class="visit__title">Эффективность</div>
      <div class="visit__text">Мы собираем данные о переходе по ссылкам из письма и кол-во отправляемых писем, чтобы вы могли удобно ослеживать эффективность различных шаблонов и пользу приложения в целом.</div>
      <div class="visit__text">Данные о переходе по ссылкам собираются с 16.03.2023</div>

      <div class="visit__fields">
        <div class="app-form__field-item">
          <div class="app-form__field-name">Статистика: От</div>
          <app-input
              v-model="date.from"
              :placeholder="'от'"
              :name="'createAtStart'"
              :type="'date'"
              :class-name="'app-form__field'"
              @input="changeDate"
          />
        </div>
        <div class="app-form__field-item">
          <div class="app-form__field-name">Статистика: До</div>
          <app-input
              v-model="date.to"
              :placeholder="'от'"
              :name="'createAtStart'"
              :type="'date'"
              :class-name="'app-form__field'"
              @input="changeDate"
          />
        </div>
      </div>

      <div class="visit__demo">Данный раздел находится в стадии тестирования. Если вы собираете статистику самостоятельно и заметили несоответствие, пожалуйста, сообщите нам об этом.</div>
    </div>
  </div>
</template>

<script>
import { PnnApi } from "../api/pnn-api";
import AppInput from '../components/base-ui/app-input'

const DAY_A_SECONDS = 1000 * 60 * 60 * 24;
const toDay = String(new Date(Date.parse(String(new Date()))).toISOString().split('T')[0]);
const fromDay = String(new Date(Date.parse(toDay) - (DAY_A_SECONDS * 7)).toISOString().split('T')[0]);
export default {
  components: { AppInput },
  data() {
    return {
      date: {
        from: fromDay,
        to: toDay
      },
      key: 1,
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: []
        },
        colors: ['#7437FD', '#ef4600', '#33D69F'],
      },
      series: [
        {
          id: 'visits',
          name: 'Переходны на сайт из писем',
          data: []
        },
        {
          id: 'emailSend',
          name: 'Отправлено писем',
          data: []
        },
        {
          id: 'newSubscriptions',
          name: 'Создано подписок',
          data: []
        }

      ]
    }
  },
  methods: {
    changeDate() {
      this.getEfficiency()
    },
    getEfficiency() {
      return PnnApi.getEfficiency({from: this.date.from, to: this.date.to})
        .then(res => {

          console.log(res)

          const keys = Object.keys(res);

          keys.forEach(key => {
            const dateList = Object.keys(res[key]).sort((a, b) => {
              if (Date.parse(a) < Date.parse(b)) {
                return -1;
              }
            })

            // Очищаем массивы
            this.options.xaxis.categories.splice(0, this.options.xaxis.categories.length)
            const valueList = this.series.find(item => item.id === key);
            valueList.data.splice(0, valueList.data.length)


            dateList.forEach(keyDate => {
              this.options.xaxis.categories.push(String(keyDate));
              const valueList = this.series.find(item => item.id === key);

              if (valueList) {
                valueList.data.push(res[key][keyDate])
              }
            })
          })



          this.key++;
        })
    },
    getData() {
      return PnnApi.visit()
        .then(res => {
            // const cat = [];
            // const val = [];

            const dateList = Object.keys(res).sort((a, b) => {
                if (Date.parse(a) < Date.parse(b)) {
                  return -1;
                }
            })

            dateList.forEach(key => {
              this.options.xaxis.categories.push(String(key));
              this.series[0].data.push(res[key])
            })

            this.key++;
        })
    }
  },
  mounted() {
    // this.getData();
    this.getEfficiency()
  },
}

</script>


<style lang="scss">
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon {
  display: none;
}

.visit {
  margin-bottom: 30px;
  display: flex;

  &__fields {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    & > div {
      width: calc(50% - 5px);
    }
  }

  &__content {
    margin-left: 30px;
    max-width: 500px;
  }
  &__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 20px;
  }
  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #747988;
    margin-bottom: 10px;
  }
  &__demo {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #8a8d96;
    margin-top: 10px;
  }
}
</style>