<template>
  <div class="pnnser-select" :class="isOpenModal ? 'active': ''">
    <div @click="toggleModal" class="pnnser-select__header">
      <div v-if="value" class="pnnser-select__header-value">{{ value }}</div>
      <div v-else class="pnnser-select__header-empty-value">{{ defaultValue }}</div>
      <div class="pnnser-select__icon">
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
          <g id="Icons with numbers" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(-432.000000, -336.000000)" fill="#000000">
              <path d="M445,344 L435,352 L435,336 L445,344 L445,344 Z M445,344" id="Shape"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="pnnser-select__modal">
      <div @click="setValue('')" class="pnnser-select__modal-item">

      </div>
      <div @click="setValue(item)" class="pnnser-select__modal-item" v-for="(item, index) in list" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-select',
  data: function () {
    return {
      value: '',
      isOpenModal: false
    }
  },
  methods: {
    emitValue: function (value) {
      this.$emit('change', value)
    },
    setValue: function (value) {
      this.value = value;
      this.closeModal();
      this.emitValue(value)
    },

    toggleModal: function () {
      this.isOpenModal = ! this.isOpenModal;
    },
    openModal: function () {
      this.isOpenModal = true;
    },
    closeModal: function () {
      this.isOpenModal = false;
    }
  },
  props: {
    list: {
      type: Array,
    },
    valueProp: {
      type: String,
    },
    defaultValue: {
      type: String,
    },
    name: {
      type: String,
    },
    className: {
      type: String,
    }
  },
  mounted() {
    this.typeResult = this.type;
  },
  watch: {
    valueProp: function(newVal) {
      this.value = newVal;
    }
  }
}
</script>

<style lang="scss">
.pnnser-select {
  position: relative;

  &__header {
    width: 100%;
    height: 50px;
    padding: 0 30px;
    border: 2px solid var(--color-border);
    border-radius: 30px;
    background: #ffffff;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    position: relative;
    z-index: 10;

    display: flex;
    justify-content: space-between;
  }
  &__header-empty-value {
    color: #7a7a7a;
    font-size: 13px;
  }
  &__icon {
    transition: .5s;

    & svg {
      display: block;

      & g {
        fill: #b6b6b6;
      }
    }
  }
  &__modal {
    position: absolute;
    width: 100%;
    top: 0px;
    border-radius: 30px;
    z-index: 9;
    background: #fff;

    height: 0;
    overflow: hidden;
    padding: 0;
  }
  &.active {
    & .pnnser-select__modal {
      height: auto;

      border: 2px solid var(--color-border);

      padding: 3px;
      padding-top: 48px;
    }

    .pnnser-select__icon {
      transform: rotate(90deg);
      transition: .5s;
    }
  }

  &__modal-item {
    border: 2px solid var(--color-border);
    border-radius: 30px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-top: 4px;
    font-size: 14px;
  }
}
</style>
