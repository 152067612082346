<template>

  <a v-if="link" target="_blank" :href="link" class="subscribers-item" :class="classList" :title="value">
    <span>{{ readableValue }}</span>
  </a>
  <div v-else class="subscribers-item" :class="classList" :title="value">
    <span>{{ readableValue }}</span>
  </div>
</template>

<script>
export default {
  name: 'app-subscribers-list-item',
  props: {
    link: {
      type: String
    },
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    classList: function () {
      let string = `subscribers-list__${this.type}`;

      if (this.color) {
        string += ` subscribers-item__color-${this.color}`
      }

      return string;
    },

    readableValue: function () {
      if (this.type === 'status') {
        return this.getReadableStatus(this.value)
      }

      if (this.type === 'phone') {
        if (this.value) {
          return this.value;
        } else {
          return '---';
        }

      }

      if (this.type === 'time') {
        return this.getReadableTime(this.value)
      }

      if (this.type === 'time-result') {
        const counterTime = new Date(`${this.value.update}`).getTime() - new Date(`${this.value.create}`).getTime();

        const d = counterTime / (1000 * 60 * 60 * 24);
        const h = counterTime / (1000 * 60 * 60) - (24 * Math.floor(d));
        const m = counterTime / (1000 * 60) - ((60 * Math.floor(h)) + (60 * 24 * Math.floor(d)));

        return ` ${Math.floor(d)} д, ${Math.floor(h)} ч, ${Math.floor(m)} м`;
      }

      return this.value;
    },
    color: function () {
      if (this.type === 'status') {
        return this.getColor(this.value)
      }

      return '';
    },
  },
  methods: {
    getReadableTime: function (value) {
      const data = new Date(String(value));

      const hours = data.getHours() <= 9 ? `0${data.getHours()}` : data.getHours();
      const minutes = data.getMinutes() <= 9 ? `0${data.getMinutes()}` : data.getMinutes();
      const mount = data.getMonth() + 1 <= 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
      const date = data.getDate() <= 9 ? `0${data.getDate()}` : data.getDate();
      const year = data.getFullYear();

      return `${hours}:${minutes} ${date}-${mount}-${year}`;
    },
    getReadableStatus: function (value) {
      if (value === 'new') {
        return 'Ожидает товар';
      } else if (value === 'success') {
        return 'Уведомлён о наличии'
      } else if (value === 'pending') {
        return 'Ожидает отправки письма'
      } else if (value === 'error') {
        return 'Ошибка'
      }

      return 'Не определен';
    },
    getColor: function (value) {
      if (value === 'new') {
        return 'yellow';
      } else if (value === 'success') {
        return 'green'
      } else if (value === 'pending') {
        return 'violet'
      } else if (value === 'error') {
        return 'red'
      }

      return 'yellow';
    }
  }
}
</script>

<style lang="scss">
.subscribers-item {
  padding: 0 20px 0 26px;

  height: 100%;
  display: flex;
  align-items: center;

  position: relative;

  &:not(:last-child):after {
    content: '';
    position: absolute;

    width: 1px;
    height: 100%;
    top: 0;
    right: -3px;
    background-color: var(--color-border);
  }

  & span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__color-yellow {
    color: var(--color-yellow);
  }
  &__color-green {
    color: var(--color-green);
  }
  &__color-red {
    color: var(--color-red);
  }
  &__color-violet {
    color: var(--color-violet);
  }

}
</style>
